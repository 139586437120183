import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import SEO from "../components/seo"
import { Image, Text } from "../components/Core"
import { Office, Hours, Phone } from "../components/locations"
import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import useForms from "../hooks/useForms"
import { Stars } from "../components/SocialReviews"
import useSocialData from "../hooks/useSocialData"

import { getPostObj } from "../utils/utils"

const ContactUsPage = ({ pageContext, location, data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const translations = data.translations.nodes[0]

  const { form } = useForms(post.formId)

  const locations = data.allLocationsJson.nodes

  return (
    <Layout
      // navSpacer
      className="contact-us"
      layoutClass="contact-fab"
      language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <div className="contact-us__hero"></div>

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="mt-3">
            <Text as="h1" className="mt-0 px-40--mobile" text={post.heading} />
            {post.contactBody.subheading && (
              <Text as="h2" text={post.contactBody.heading} />
            )}
            <Text
              className="contact-us__blurb px-40--mobile"
              text={post.contactBody.blurb}
            />
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column flex px-40--mobile contact-us__content-container">
            <div className="contact-us__sidebar">
              <Image publicId={post.heroImage} />
              <div className="flex mt-2 contact-us__location-address">
                {locations.map((local, i) => {
                  const { social } = useSocialData(local.locationData.gmbId)

                  return (
                    <div className="contact-us__sidebar-address" key={i}>
                      <p className="bold">
                        {!local.pageData.hasThisPage ? (
                          `${local.locationData.locationName}`
                        ) : (
                          <Link
                            to={`/${local.pageData.title}`}
                            title={`${translations.learnMoreAboutOur[language]} ${local.locationData.locationName} ${translations.office[language]}`}>{`${local.locationData.locationName}`}</Link>
                        )}
                      </p>
                      <Office
                        language={language}
                        city={local.locationData.cityName}
                        state={local.locationData.stateAbbr}
                        zip={local.locationData.cityZip}
                        streetName={local.locationData.streetName}
                        streetNumber={local.locationData.streetNumber}
                        gmb={local.locationData.googlePlaceLink}
                      />

                      {social && social.rating && (
                        <a
                          target="_blank"
                          className="mt-1 mb-1 contact-us__sidebar-ratings"
                          href={social.url}
                          title={`View our reviews`}>
                          <Stars rating={social.rating} />
                          <span>({social.totalReviews})</span>
                        </a>
                      )}
                      {
                        <div
                          className={`${
                            i === 0
                              ? "contact-us__is-hidden-mobile"
                              : "contact-us__is-hidden-desktop"
                          }`}>
                          <Text
                            className="mb-0"
                            as="h5"
                            text={translations.hours[language]}
                          />
                          <Hours
                            key={i}
                            language={language}
                            locationHours={local.locationData.locationHours}
                          />
                        </div>
                      }
                      {i === 1 && local.locationData.phone && (
                        <>
                          <Text
                            className="mt-1 mb-0"
                            as="h5"
                            text={translations.phone[language]}
                          />
                          <p className="my-0">
                            <Phone phoneNumber={local.locationData.phone} />
                          </p>
                        </>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="contact-us__form">
              {language === "en" && <Seamless src={form.embedUrl} />}
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heroImage
        metaTitle
        metaDescription
        heading
        contactBody {
          heading
          blurb
        }
        formId
      }
    }
    allLocationsJson(
      filter: { useOnFooter: { eq: true } }
      sort: { fields: locationData___order }
    ) {
      nodes {
        locationData {
          ...locationDataFields
        }
        pageData {
          hasThisPage
          ...locationPageURL
          layout_3 {
            formSection {
              hasForm
              seamlessShortcode
            }
          }
        }
      }
    }
    forms: allDataJson(filter: { optionsItem: { eq: "seamless" } }) {
      nodes {
        allSeamlessForms {
          embedUrl
          seamlessShortcode
          title
        }
      }
    }
    translations: allTranslationsJson {
      nodes {
        address {
          en
          es
        }
        hours {
          en
          es
        }
        phone {
          en
          es
        }
        office {
          en
          es
        }
        learnMoreAboutOur {
          en
          es
        }
      }
    }
  }
`

export default ContactUsPage
